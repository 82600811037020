import clientConfig0 from '/app/node_modules/@vuepress/plugin-active-header-links/lib/client/config.js'
import clientConfig1 from '/app/node_modules/@vuepress/plugin-back-to-top/lib/client/config.js'
import clientConfig2 from '/app/node_modules/@vuepress/plugin-external-link-icon/lib/client/config.js'
import clientConfig3 from '/app/node_modules/@vuepress/plugin-medium-zoom/lib/client/config.js'
import clientConfig4 from '/app/node_modules/@vuepress/plugin-nprogress/lib/client/config.js'
import clientConfig5 from '/app/node_modules/@vuepress/plugin-theme-data/lib/client/config.js'
import clientConfig6 from '/app/node_modules/@vuepress/theme-default/lib/client/config.js'
import clientConfig7 from '/app/docs/.vuepress/.temp/register-components/clientConfig.1123c68c.js'
import clientConfig8 from '/app/node_modules/@vuepress/plugin-search/lib/client/config.js'
import clientConfig9 from '/app/node_modules/vuepress-plugin-clipboard/lib/client/config.js'
import clientConfig10 from '/app/docs/.vuepress/client.ts'

export const clientConfigs = [
  clientConfig0,
  clientConfig1,
  clientConfig2,
  clientConfig3,
  clientConfig4,
  clientConfig5,
  clientConfig6,
  clientConfig7,
  clientConfig8,
  clientConfig9,
  clientConfig10,
]
