import { defineAsyncComponent } from 'vue'

export const pagesComponents = {
  // path: /
  "v-8daa1a0e": defineAsyncComponent(() => import(/* webpackChunkName: "v-8daa1a0e" */"/app/docs/.vuepress/.temp/pages/index.html.vue")),
  // path: /archive/C_sharp.html
  "v-3d00c951": defineAsyncComponent(() => import(/* webpackChunkName: "v-3d00c951" */"/app/docs/.vuepress/.temp/pages/archive/C_sharp.html.vue")),
  // path: /archive/android.html
  "v-15559070": defineAsyncComponent(() => import(/* webpackChunkName: "v-15559070" */"/app/docs/.vuepress/.temp/pages/archive/android.html.vue")),
  // path: /archive/ios.html
  "v-c59e76dc": defineAsyncComponent(() => import(/* webpackChunkName: "v-c59e76dc" */"/app/docs/.vuepress/.temp/pages/archive/ios.html.vue")),
  // path: /todo/collection-schemas.html
  "v-843656b4": defineAsyncComponent(() => import(/* webpackChunkName: "v-843656b4" */"/app/docs/.vuepress/.temp/pages/todo/collection-schemas.html.vue")),
  // path: /todo/feature-feautures.html
  "v-7c971834": defineAsyncComponent(() => import(/* webpackChunkName: "v-7c971834" */"/app/docs/.vuepress/.temp/pages/todo/feature-feautures.html.vue")),
  // path: /todo/governance-only.html
  "v-9311485c": defineAsyncComponent(() => import(/* webpackChunkName: "v-9311485c" */"/app/docs/.vuepress/.temp/pages/todo/governance-only.html.vue")),
  // path: /about/approach.html
  "v-02db6f96": defineAsyncComponent(() => import(/* webpackChunkName: "v-02db6f96" */"/app/docs/.vuepress/.temp/pages/about/approach.html.vue")),
  // path: /about/
  "v-74bc627b": defineAsyncComponent(() => import(/* webpackChunkName: "v-74bc627b" */"/app/docs/.vuepress/.temp/pages/about/index.html.vue")),
  // path: /about/networks.html
  "v-f864654e": defineAsyncComponent(() => import(/* webpackChunkName: "v-f864654e" */"/app/docs/.vuepress/.temp/pages/about/networks.html.vue")),
  // path: /about/timeline.html
  "v-1d52aa9d": defineAsyncComponent(() => import(/* webpackChunkName: "v-1d52aa9d" */"/app/docs/.vuepress/.temp/pages/about/timeline.html.vue")),
  // path: /build/
  "v-777a1bba": defineAsyncComponent(() => import(/* webpackChunkName: "v-777a1bba" */"/app/docs/.vuepress/.temp/pages/build/index.html.vue")),
  // path: /products/
  "v-807a9f1c": defineAsyncComponent(() => import(/* webpackChunkName: "v-807a9f1c" */"/app/docs/.vuepress/.temp/pages/products/index.html.vue")),
  // path: /reference/
  "v-22a2f9fd": defineAsyncComponent(() => import(/* webpackChunkName: "v-22a2f9fd" */"/app/docs/.vuepress/.temp/pages/reference/index.html.vue")),
  // path: /reference/sdk-endpoints.html
  "v-383a74cb": defineAsyncComponent(() => import(/* webpackChunkName: "v-383a74cb" */"/app/docs/.vuepress/.temp/pages/reference/sdk-endpoints.html.vue")),
  // path: /reference/sdk-methods.html
  "v-803929d2": defineAsyncComponent(() => import(/* webpackChunkName: "v-803929d2" */"/app/docs/.vuepress/.temp/pages/reference/sdk-methods.html.vue")),
  // path: /reference/tools.html
  "v-75b1c99b": defineAsyncComponent(() => import(/* webpackChunkName: "v-75b1c99b" */"/app/docs/.vuepress/.temp/pages/reference/tools.html.vue")),
  // path: /tutorials/
  "v-0efe29b3": defineAsyncComponent(() => import(/* webpackChunkName: "v-0efe29b3" */"/app/docs/.vuepress/.temp/pages/tutorials/index.html.vue")),
  // path: /tutorials/mass-transactions.html
  "v-4558477e": defineAsyncComponent(() => import(/* webpackChunkName: "v-4558477e" */"/app/docs/.vuepress/.temp/pages/tutorials/mass-transactions.html.vue")),
  // path: /tutorials/wallet-integration-old.html
  "v-901172a8": defineAsyncComponent(() => import(/* webpackChunkName: "v-901172a8" */"/app/docs/.vuepress/.temp/pages/tutorials/wallet-integration-old.html.vue")),
  // path: /todo/ecosystem/polkadot.html
  "v-5ed06074": defineAsyncComponent(() => import(/* webpackChunkName: "v-5ed06074" */"/app/docs/.vuepress/.temp/pages/todo/ecosystem/polkadot.html.vue")),
  // path: /todo/ecosystem/substrate.html
  "v-08bd9a92": defineAsyncComponent(() => import(/* webpackChunkName: "v-08bd9a92" */"/app/docs/.vuepress/.temp/pages/todo/ecosystem/substrate.html.vue")),
  // path: /todo/network-features/did.html
  "v-60c36fc6": defineAsyncComponent(() => import(/* webpackChunkName: "v-60c36fc6" */"/app/docs/.vuepress/.temp/pages/todo/network-features/did.html.vue")),
  // path: /todo/network-features/onchain-metadata.html
  "v-2866c13c": defineAsyncComponent(() => import(/* webpackChunkName: "v-2866c13c" */"/app/docs/.vuepress/.temp/pages/todo/network-features/onchain-metadata.html.vue")),
  // path: /todo/network-features/rate-limits.html
  "v-1f351892": defineAsyncComponent(() => import(/* webpackChunkName: "v-1f351892" */"/app/docs/.vuepress/.temp/pages/todo/network-features/rate-limits.html.vue")),
  // path: /todo/network-features/refungibility.html
  "v-21539485": defineAsyncComponent(() => import(/* webpackChunkName: "v-21539485" */"/app/docs/.vuepress/.temp/pages/todo/network-features/refungibility.html.vue")),
  // path: /todo/network-features/royalties.html
  "v-bb03485c": defineAsyncComponent(() => import(/* webpackChunkName: "v-bb03485c" */"/app/docs/.vuepress/.temp/pages/todo/network-features/royalties.html.vue")),
  // path: /todo/network-features/scheduling.html
  "v-904b03fc": defineAsyncComponent(() => import(/* webpackChunkName: "v-904b03fc" */"/app/docs/.vuepress/.temp/pages/todo/network-features/scheduling.html.vue")),
  // path: /todo/schemas/ERC721Metadata.html
  "v-438d0d86": defineAsyncComponent(() => import(/* webpackChunkName: "v-438d0d86" */"/app/docs/.vuepress/.temp/pages/todo/schemas/ERC721Metadata.html.vue")),
  // path: /todo/schemas/unique.html
  "v-ce97a68a": defineAsyncComponent(() => import(/* webpackChunkName: "v-ce97a68a" */"/app/docs/.vuepress/.temp/pages/todo/schemas/unique.html.vue")),
  // path: /about/network-features/evm.html
  "v-70956a15": defineAsyncComponent(() => import(/* webpackChunkName: "v-70956a15" */"/app/docs/.vuepress/.temp/pages/about/network-features/evm.html.vue")),
  // path: /about/network-features/marketplace.html
  "v-850b81f4": defineAsyncComponent(() => import(/* webpackChunkName: "v-850b81f4" */"/app/docs/.vuepress/.temp/pages/about/network-features/marketplace.html.vue")),
  // path: /about/network-features/pricing.html
  "v-17da2b2a": defineAsyncComponent(() => import(/* webpackChunkName: "v-17da2b2a" */"/app/docs/.vuepress/.temp/pages/about/network-features/pricing.html.vue")),
  // path: /about/network-features/sdk-indexer.html
  "v-18fc2785": defineAsyncComponent(() => import(/* webpackChunkName: "v-18fc2785" */"/app/docs/.vuepress/.temp/pages/about/network-features/sdk-indexer.html.vue")),
  // path: /about/network-features/sponsoring.html
  "v-d8d50a8e": defineAsyncComponent(() => import(/* webpackChunkName: "v-d8d50a8e" */"/app/docs/.vuepress/.temp/pages/about/network-features/sponsoring.html.vue")),
  // path: /about/network-features/staking.html
  "v-34c92f1a": defineAsyncComponent(() => import(/* webpackChunkName: "v-34c92f1a" */"/app/docs/.vuepress/.temp/pages/about/network-features/staking.html.vue")),
  // path: /about/nft-features/composable.html
  "v-1f52b5f4": defineAsyncComponent(() => import(/* webpackChunkName: "v-1f52b5f4" */"/app/docs/.vuepress/.temp/pages/about/nft-features/composable.html.vue")),
  // path: /about/nft-features/customizable.html
  "v-9d4cbf92": defineAsyncComponent(() => import(/* webpackChunkName: "v-9d4cbf92" */"/app/docs/.vuepress/.temp/pages/about/nft-features/customizable.html.vue")),
  // path: /about/nft-features/dynamic.html
  "v-319a1920": defineAsyncComponent(() => import(/* webpackChunkName: "v-319a1920" */"/app/docs/.vuepress/.temp/pages/about/nft-features/dynamic.html.vue")),
  // path: /about/nft-features/multimedia.html
  "v-32983e88": defineAsyncComponent(() => import(/* webpackChunkName: "v-32983e88" */"/app/docs/.vuepress/.temp/pages/about/nft-features/multimedia.html.vue")),
  // path: /about/nft-features/native.html
  "v-af6a7d88": defineAsyncComponent(() => import(/* webpackChunkName: "v-af6a7d88" */"/app/docs/.vuepress/.temp/pages/about/nft-features/native.html.vue")),
  // path: /about/nft-features/nested.html
  "v-524b8748": defineAsyncComponent(() => import(/* webpackChunkName: "v-524b8748" */"/app/docs/.vuepress/.temp/pages/about/nft-features/nested.html.vue")),
  // path: /about/nft-formats/dynamic-nfts.html
  "v-5c8fcf95": defineAsyncComponent(() => import(/* webpackChunkName: "v-5c8fcf95" */"/app/docs/.vuepress/.temp/pages/about/nft-formats/dynamic-nfts.html.vue")),
  // path: /about/token-types/coins.html
  "v-2b9a5a08": defineAsyncComponent(() => import(/* webpackChunkName: "v-2b9a5a08" */"/app/docs/.vuepress/.temp/pages/about/token-types/coins.html.vue")),
  // path: /about/token-types/main-types.html
  "v-e97a5262": defineAsyncComponent(() => import(/* webpackChunkName: "v-e97a5262" */"/app/docs/.vuepress/.temp/pages/about/token-types/main-types.html.vue")),
  // path: /about/token-types/nft.html
  "v-5482727c": defineAsyncComponent(() => import(/* webpackChunkName: "v-5482727c" */"/app/docs/.vuepress/.temp/pages/about/token-types/nft.html.vue")),
  // path: /about/token-types/rft.html
  "v-75437a3e": defineAsyncComponent(() => import(/* webpackChunkName: "v-75437a3e" */"/app/docs/.vuepress/.temp/pages/about/token-types/rft.html.vue")),
  // path: /build/evm/UniqueNFT.html
  "v-2944117b": defineAsyncComponent(() => import(/* webpackChunkName: "v-2944117b" */"/app/docs/.vuepress/.temp/pages/build/evm/UniqueNFT.html.vue")),
  // path: /build/evm/accounts.html
  "v-34149d74": defineAsyncComponent(() => import(/* webpackChunkName: "v-34149d74" */"/app/docs/.vuepress/.temp/pages/build/evm/accounts.html.vue")),
  // path: /build/evm/
  "v-9d3d3ee6": defineAsyncComponent(() => import(/* webpackChunkName: "v-9d3d3ee6" */"/app/docs/.vuepress/.temp/pages/build/evm/index.html.vue")),
  // path: /build/evm/todo-setting-project.html
  "v-171404f7": defineAsyncComponent(() => import(/* webpackChunkName: "v-171404f7" */"/app/docs/.vuepress/.temp/pages/build/evm/todo-setting-project.html.vue")),
  // path: /build/sdk/about_sdk.html
  "v-5408c100": defineAsyncComponent(() => import(/* webpackChunkName: "v-5408c100" */"/app/docs/.vuepress/.temp/pages/build/sdk/about_sdk.html.vue")),
  // path: /build/sdk/accounts.html
  "v-817131b8": defineAsyncComponent(() => import(/* webpackChunkName: "v-817131b8" */"/app/docs/.vuepress/.temp/pages/build/sdk/accounts.html.vue")),
  // path: /build/sdk/architecture.html
  "v-57971552": defineAsyncComponent(() => import(/* webpackChunkName: "v-57971552" */"/app/docs/.vuepress/.temp/pages/build/sdk/architecture.html.vue")),
  // path: /build/sdk/collections.html
  "v-61b60c53": defineAsyncComponent(() => import(/* webpackChunkName: "v-61b60c53" */"/app/docs/.vuepress/.temp/pages/build/sdk/collections.html.vue")),
  // path: /build/sdk/examples-nesting.html
  "v-49536104": defineAsyncComponent(() => import(/* webpackChunkName: "v-49536104" */"/app/docs/.vuepress/.temp/pages/build/sdk/examples-nesting.html.vue")),
  // path: /build/sdk/examplesCustomExtrinsic.html
  "v-e6af5aaa": defineAsyncComponent(() => import(/* webpackChunkName: "v-e6af5aaa" */"/app/docs/.vuepress/.temp/pages/build/sdk/examplesCustomExtrinsic.html.vue")),
  // path: /build/sdk/examplesLifeNFT.html
  "v-5d3b9f11": defineAsyncComponent(() => import(/* webpackChunkName: "v-5d3b9f11" */"/app/docs/.vuepress/.temp/pages/build/sdk/examplesLifeNFT.html.vue")),
  // path: /build/sdk/examplesSubstrateREST.html
  "v-dd9ff208": defineAsyncComponent(() => import(/* webpackChunkName: "v-dd9ff208" */"/app/docs/.vuepress/.temp/pages/build/sdk/examplesSubstrateREST.html.vue")),
  // path: /build/sdk/getting-started.html
  "v-0b7e2150": defineAsyncComponent(() => import(/* webpackChunkName: "v-0b7e2150" */"/app/docs/.vuepress/.temp/pages/build/sdk/getting-started.html.vue")),
  // path: /build/sdk/refungible.html
  "v-fcab5e46": defineAsyncComponent(() => import(/* webpackChunkName: "v-fcab5e46" */"/app/docs/.vuepress/.temp/pages/build/sdk/refungible.html.vue")),
  // path: /build/sdk/sponsoring.html
  "v-7b532cfc": defineAsyncComponent(() => import(/* webpackChunkName: "v-7b532cfc" */"/app/docs/.vuepress/.temp/pages/build/sdk/sponsoring.html.vue")),
  // path: /build/sdk/tokens.html
  "v-380a7630": defineAsyncComponent(() => import(/* webpackChunkName: "v-380a7630" */"/app/docs/.vuepress/.temp/pages/build/sdk/tokens.html.vue")),
  // path: /build/sdk/tools.html
  "v-d756f9e6": defineAsyncComponent(() => import(/* webpackChunkName: "v-d756f9e6" */"/app/docs/.vuepress/.temp/pages/build/sdk/tools.html.vue")),
  // path: /products/market/
  "v-b5b9827e": defineAsyncComponent(() => import(/* webpackChunkName: "v-b5b9827e" */"/app/docs/.vuepress/.temp/pages/products/market/index.html.vue")),
  // path: /products/minter/
  "v-9ad48664": defineAsyncComponent(() => import(/* webpackChunkName: "v-9ad48664" */"/app/docs/.vuepress/.temp/pages/products/minter/index.html.vue")),
  // path: /products/scan/
  "v-31125fc0": defineAsyncComponent(() => import(/* webpackChunkName: "v-31125fc0" */"/app/docs/.vuepress/.temp/pages/products/scan/index.html.vue")),
  // path: /products/wallet/
  "v-946512b8": defineAsyncComponent(() => import(/* webpackChunkName: "v-946512b8" */"/app/docs/.vuepress/.temp/pages/products/wallet/index.html.vue")),
  // path: /reference/blockchain/collections.html
  "v-669452a2": defineAsyncComponent(() => import(/* webpackChunkName: "v-669452a2" */"/app/docs/.vuepress/.temp/pages/reference/blockchain/collections.html.vue")),
  // path: /reference/blockchain/contract-helpers.html
  "v-66883ad1": defineAsyncComponent(() => import(/* webpackChunkName: "v-66883ad1" */"/app/docs/.vuepress/.temp/pages/reference/blockchain/contract-helpers.html.vue")),
  // path: /reference/blockchain/events.html
  "v-79df933c": defineAsyncComponent(() => import(/* webpackChunkName: "v-79df933c" */"/app/docs/.vuepress/.temp/pages/reference/blockchain/events.html.vue")),
  // path: /reference/blockchain/extrinsics.html
  "v-4c646ebf": defineAsyncComponent(() => import(/* webpackChunkName: "v-4c646ebf" */"/app/docs/.vuepress/.temp/pages/reference/blockchain/extrinsics.html.vue")),
  // path: /reference/blockchain/nesting.html
  "v-94f46c66": defineAsyncComponent(() => import(/* webpackChunkName: "v-94f46c66" */"/app/docs/.vuepress/.temp/pages/reference/blockchain/nesting.html.vue")),
  // path: /reference/blockchain/owner-admin-roles.html
  "v-26093d12": defineAsyncComponent(() => import(/* webpackChunkName: "v-26093d12" */"/app/docs/.vuepress/.temp/pages/reference/blockchain/owner-admin-roles.html.vue")),
  // path: /reference/blockchain/properties.html
  "v-bd5e8cf0": defineAsyncComponent(() => import(/* webpackChunkName: "v-bd5e8cf0" */"/app/docs/.vuepress/.temp/pages/reference/blockchain/properties.html.vue")),
  // path: /reference/blockchain/rpc.html
  "v-f7ffe35c": defineAsyncComponent(() => import(/* webpackChunkName: "v-f7ffe35c" */"/app/docs/.vuepress/.temp/pages/reference/blockchain/rpc.html.vue")),
  // path: /reference/schemas/
  "v-4ea60c7a": defineAsyncComponent(() => import(/* webpackChunkName: "v-4ea60c7a" */"/app/docs/.vuepress/.temp/pages/reference/schemas/index.html.vue")),
  // path: /tutorials/evm/eth-general.html
  "v-5dd742ab": defineAsyncComponent(() => import(/* webpackChunkName: "v-5dd742ab" */"/app/docs/.vuepress/.temp/pages/tutorials/evm/eth-general.html.vue")),
  // path: /tutorials/evm/how-to-ethereum.html
  "v-2b1cb35a": defineAsyncComponent(() => import(/* webpackChunkName: "v-2b1cb35a" */"/app/docs/.vuepress/.temp/pages/tutorials/evm/how-to-ethereum.html.vue")),
  // path: /tutorials/evm/ready-samples.html
  "v-ef190240": defineAsyncComponent(() => import(/* webpackChunkName: "v-ef190240" */"/app/docs/.vuepress/.temp/pages/tutorials/evm/ready-samples.html.vue")),
  // path: /tutorials/evm/using-contracts.html
  "v-57b98f47": defineAsyncComponent(() => import(/* webpackChunkName: "v-57b98f47" */"/app/docs/.vuepress/.temp/pages/tutorials/evm/using-contracts.html.vue")),
  // path: /tutorials/evm/using-sol-interfaces.html
  "v-84d1531a": defineAsyncComponent(() => import(/* webpackChunkName: "v-84d1531a" */"/app/docs/.vuepress/.temp/pages/tutorials/evm/using-sol-interfaces.html.vue")),
  // path: /tutorials/minting/customizable-nfts.html
  "v-00fb0253": defineAsyncComponent(() => import(/* webpackChunkName: "v-00fb0253" */"/app/docs/.vuepress/.temp/pages/tutorials/minting/customizable-nfts.html.vue")),
  // path: /tutorials/minting/generative-nft.html
  "v-980f3600": defineAsyncComponent(() => import(/* webpackChunkName: "v-980f3600" */"/app/docs/.vuepress/.temp/pages/tutorials/minting/generative-nft.html.vue")),
  // path: /tutorials/minting/main.html
  "v-7fccee5e": defineAsyncComponent(() => import(/* webpackChunkName: "v-7fccee5e" */"/app/docs/.vuepress/.temp/pages/tutorials/minting/main.html.vue")),
  // path: /tutorials/minting/mass-listing.html
  "v-1f180cac": defineAsyncComponent(() => import(/* webpackChunkName: "v-1f180cac" */"/app/docs/.vuepress/.temp/pages/tutorials/minting/mass-listing.html.vue")),
  // path: /tutorials/minting/mass-minting.html
  "v-615a7c70": defineAsyncComponent(() => import(/* webpackChunkName: "v-615a7c70" */"/app/docs/.vuepress/.temp/pages/tutorials/minting/mass-minting.html.vue")),
  // path: /tutorials/minting/prepare-account.html
  "v-67346f74": defineAsyncComponent(() => import(/* webpackChunkName: "v-67346f74" */"/app/docs/.vuepress/.temp/pages/tutorials/minting/prepare-account.html.vue")),
  // path: /tutorials/minting/prepare-scripts.html
  "v-06a07d0e": defineAsyncComponent(() => import(/* webpackChunkName: "v-06a07d0e" */"/app/docs/.vuepress/.temp/pages/tutorials/minting/prepare-scripts.html.vue")),
  // path: /tutorials/minting/setup-environment.html
  "v-0d4ca118": defineAsyncComponent(() => import(/* webpackChunkName: "v-0d4ca118" */"/app/docs/.vuepress/.temp/pages/tutorials/minting/setup-environment.html.vue")),
  // path: /tutorials/user-guides/ledger-connect.html
  "v-8a185bac": defineAsyncComponent(() => import(/* webpackChunkName: "v-8a185bac" */"/app/docs/.vuepress/.temp/pages/tutorials/user-guides/ledger-connect.html.vue")),
  // path: /tutorials/user-guides/polkadotjs.html
  "v-340ea201": defineAsyncComponent(() => import(/* webpackChunkName: "v-340ea201" */"/app/docs/.vuepress/.temp/pages/tutorials/user-guides/polkadotjs.html.vue")),
  // path: /tutorials/user-guides/wallets.html
  "v-58c12348": defineAsyncComponent(() => import(/* webpackChunkName: "v-58c12348" */"/app/docs/.vuepress/.temp/pages/tutorials/user-guides/wallets.html.vue")),
  // path: /build/evm/smart-contracts/collection-helpers.html
  "v-d1a0a4b8": defineAsyncComponent(() => import(/* webpackChunkName: "v-d1a0a4b8" */"/app/docs/.vuepress/.temp/pages/build/evm/smart-contracts/collection-helpers.html.vue")),
  // path: /build/evm/smart-contracts/contract-helpers.html
  "v-6b79cbf0": defineAsyncComponent(() => import(/* webpackChunkName: "v-6b79cbf0" */"/app/docs/.vuepress/.temp/pages/build/evm/smart-contracts/contract-helpers.html.vue")),
  // path: /build/evm/smart-contracts/
  "v-206d57bf": defineAsyncComponent(() => import(/* webpackChunkName: "v-206d57bf" */"/app/docs/.vuepress/.temp/pages/build/evm/smart-contracts/index.html.vue")),
  // path: /build/evm/smart-contracts/writing-contracts.html
  "v-2f3fdb68": defineAsyncComponent(() => import(/* webpackChunkName: "v-2f3fdb68" */"/app/docs/.vuepress/.temp/pages/build/evm/smart-contracts/writing-contracts.html.vue")),
  // path: /build/sdk/v2/asset-hub.html
  "v-56815125": defineAsyncComponent(() => import(/* webpackChunkName: "v-56815125" */"/app/docs/.vuepress/.temp/pages/build/sdk/v2/asset-hub.html.vue")),
  // path: /build/sdk/v2/balances.html
  "v-8c6ba084": defineAsyncComponent(() => import(/* webpackChunkName: "v-8c6ba084" */"/app/docs/.vuepress/.temp/pages/build/sdk/v2/balances.html.vue")),
  // path: /build/sdk/v2/collections.html
  "v-73a45548": defineAsyncComponent(() => import(/* webpackChunkName: "v-73a45548" */"/app/docs/.vuepress/.temp/pages/build/sdk/v2/collections.html.vue")),
  // path: /build/sdk/v2/dapps.html
  "v-ff8a44b2": defineAsyncComponent(() => import(/* webpackChunkName: "v-ff8a44b2" */"/app/docs/.vuepress/.temp/pages/build/sdk/v2/dapps.html.vue")),
  // path: /build/sdk/v2/evm.html
  "v-093ea961": defineAsyncComponent(() => import(/* webpackChunkName: "v-093ea961" */"/app/docs/.vuepress/.temp/pages/build/sdk/v2/evm.html.vue")),
  // path: /build/sdk/v2/indexer.html
  "v-9a8453c4": defineAsyncComponent(() => import(/* webpackChunkName: "v-9a8453c4" */"/app/docs/.vuepress/.temp/pages/build/sdk/v2/indexer.html.vue")),
  // path: /build/sdk/v2/migration.html
  "v-0b8f2baf": defineAsyncComponent(() => import(/* webpackChunkName: "v-0b8f2baf" */"/app/docs/.vuepress/.temp/pages/build/sdk/v2/migration.html.vue")),
  // path: /build/sdk/v2/quick-start.html
  "v-341f2e7b": defineAsyncComponent(() => import(/* webpackChunkName: "v-341f2e7b" */"/app/docs/.vuepress/.temp/pages/build/sdk/v2/quick-start.html.vue")),
  // path: /build/sdk/v2/sponsoring.html
  "v-489ea426": defineAsyncComponent(() => import(/* webpackChunkName: "v-489ea426" */"/app/docs/.vuepress/.temp/pages/build/sdk/v2/sponsoring.html.vue")),
  // path: /build/sdk/v2/tokens.html
  "v-3f75a81b": defineAsyncComponent(() => import(/* webpackChunkName: "v-3f75a81b" */"/app/docs/.vuepress/.temp/pages/build/sdk/v2/tokens.html.vue")),
  // path: /build/tech-concepts/addresses/
  "v-8cfdeb58": defineAsyncComponent(() => import(/* webpackChunkName: "v-8cfdeb58" */"/app/docs/.vuepress/.temp/pages/build/tech-concepts/addresses/index.html.vue")),
  // path: /build/tech-concepts/balances/
  "v-481a5791": defineAsyncComponent(() => import(/* webpackChunkName: "v-481a5791" */"/app/docs/.vuepress/.temp/pages/build/tech-concepts/balances/index.html.vue")),
  // path: /404.html
  "v-3706649a": defineAsyncComponent(() => import(/* webpackChunkName: "v-3706649a" */"/app/docs/.vuepress/.temp/pages/404.html.vue")),
}
