import { defineAsyncComponent } from 'vue'

export default {
  enhance: ({ app }) => {    
      app.component("Button", defineAsyncComponent(() => import("/app/docs/.vuepress/components/Button.vue"))),
      app.component("ChainTable", defineAsyncComponent(() => import("/app/docs/.vuepress/components/ChainTable.vue"))),
      app.component("CollectionAddressCoder", defineAsyncComponent(() => import("/app/docs/.vuepress/components/CollectionAddressCoder.vue"))),
      app.component("CopyButton", defineAsyncComponent(() => import("/app/docs/.vuepress/components/CopyButton.vue"))),
      app.component("Details", defineAsyncComponent(() => import("/app/docs/.vuepress/components/Details.vue"))),
      app.component("HomePageSections", defineAsyncComponent(() => import("/app/docs/.vuepress/components/HomePageSections.vue"))),
      app.component("Link", defineAsyncComponent(() => import("/app/docs/.vuepress/components/Link.vue"))),
      app.component("Logo", defineAsyncComponent(() => import("/app/docs/.vuepress/components/Logo.vue"))),
      app.component("Redirect", defineAsyncComponent(() => import("/app/docs/.vuepress/components/Redirect.vue"))),
      app.component("SubEthCoder", defineAsyncComponent(() => import("/app/docs/.vuepress/components/SubEthCoder.vue"))),
      app.component("TestMetamask", defineAsyncComponent(() => import("/app/docs/.vuepress/components/TestMetamask.vue"))),
      app.component("WalletTable", defineAsyncComponent(() => import("/app/docs/.vuepress/components/WalletTable.vue"))),
      app.component("UI-UniqueButton", defineAsyncComponent(() => import("/app/docs/.vuepress/components/UI/UniqueButton.vue"))),
      app.component("UI-UniqueInput", defineAsyncComponent(() => import("/app/docs/.vuepress/components/UI/UniqueInput.vue")))
  },
}
